import { GetStaticProps } from 'next';

import { enhancedStaticProps, getTranslationForLocale } from '~lib';
import { CollectionItems } from '~lib/storyblok/sdk';

import Error from './_error';

interface NotFoundPageProps {
  filtersMapping?: any;
  collections: CollectionItems;
}

const NotFoundPage = (props) => <Error statusCode={404} {...props} />;

export default NotFoundPage;

export const getStaticProps: GetStaticProps<NotFoundPageProps> =
  enhancedStaticProps(async ({ defaultLocale, locale, sdk }) => {
    let collections = null;
    let notFound = false;
    const { lang, needsTranslation } = getTranslationForLocale(
      locale,
      defaultLocale,
    );

    const filtersMapping =
      (await sdk.filtersMapping())?.FiltersItem?.content || {};

    try {
      collections = (
        await sdk.collectionItems({
          filter: { isHidden: { is: 'false' } },
          prefix: `${needsTranslation ? `${lang}/*` : ''}`,
        })
      ).CollectionItems;
    } catch (e) {}

    if (notFound) {
      return {
        props: {
          filtersMapping: {},
        },
        notFound: true,
      };
    }

    return {
      props: {
        collections,
        filtersMapping,
      },
      notFound,
      revalidate: 60,
    };
  });
