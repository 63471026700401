import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';

import { Container, Layout, Heading, Text, Seo } from '~components';
import { getTranslationForLocale } from '~lib';
import { Search } from '~components/search';
import { storyblokSdk } from '~lib/storyblok/client';
import {
  CollectionItems,
  NavigationItem,
  FooterItem,
} from '~lib/storyblok/sdk';

interface ErrorPageProps {
  filtersMapping?: any;
  collections?: CollectionItems;
  navigation?: NavigationItem;
  footer?: FooterItem;
  statusCode?: number;
}

const messages = defineMessages({
  unauthorizedTitle: 'Unauthorized',
  unauthorizedDescription: "It looks like you're not supposed to be here.",
  notFoundTitle: 'Page not found',
  notFoundDescription:
    'Try searching for something or check out these categories:',
  errorTitle: 'Error',
  errorDescription: 'An unknown error has occured.',
});

const getError = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

const getContent = ({ statusCode, intl }) => {
  switch (statusCode) {
    case 401:
      return {
        title: intl.formatMessage(messages.unauthorizedTitle),
        description: intl.formatMessage(messages.unauthorizedDescription),
      };
    case 404:
      return {
        title: intl.formatMessage(messages.notFoundTitle),
        description: intl.formatMessage(messages.notFoundDescription),
      };
    default:
      return {
        title: intl.formatMessage(messages.errorTitle),
        description: intl.formatMessage(messages.errorDescription),
      };
  }
};

const Error = ({
  collections,
  filtersMapping,
  navigation,
  footer,
  statusCode,
}: ErrorPageProps) => {
  const { asPath } = useRouter();
  const intl = useIntl();
  const { title, description } = getContent({ statusCode, intl });

  return (
    <>
      <Seo title={title} description={description} />

      <Layout navigation={navigation} footer={footer}>
        <Container textAlign="center" pb={[3, 4]}>
          <Heading variant="h1" mb={1} lineHeight="normal" color="inherit">
            {title}
          </Heading>
          <Text
            lineHeight="150%"
            fontSize={1.75}
            color="inherit"
            maxWidth="400px"
            mx="auto"
          >
            {description}
          </Text>
        </Container>

        <Search
          collections={collections}
          filtersMapping={filtersMapping}
          path={asPath?.split('?')?.[0]}
          pt={0}
        />
      </Layout>
    </>
  );
};

Error.getInitialProps = async ({ defaultLocale, locale, res, err }) => {
  let navigation;
  const { lang, needsTranslation } = getTranslationForLocale(
    locale,
    defaultLocale,
  );

  try {
    navigation = (
      await storyblokSdk.navigation({
        slug: `${needsTranslation ? `${lang}/` : ''}navigation`,
      })
    ).NavigationItem;
  } catch (e) {}

  return {
    props: {
      navigation,
    },
    ...getError({ res, err }),
  };
};

export default Error;
