import { useEffect, useCallback, useState } from 'react';
import { Box, Flex, css, SystemProps } from '@storyofams/react-ui';
import { pick } from '@styled-system/props';
import { debounce, omit } from 'lodash';
import { useRouter } from 'next/router';
import qs from 'qs';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Input, Button, Container } from '~components';
import { Search as SearchIcon } from '~components/common/Icons';
import Grid from '~components/products/Grid';
import { CollectionSection } from '~components/sections';
import { CollectionItems } from '~lib/storyblok/sdk';

interface SearchProps extends SystemProps {
  filtersMapping?: any;
  collections: CollectionItems;
  path?: string;
}

const messages = defineMessages({
  searchPlaceholder:
    'Search for products, flavours, cocktails or anything else...',
  clear: 'CLEAR',
});

export const Search = ({
  collections,
  filtersMapping,
  path,
  ...props
}: SearchProps) => {
  const intl = useIntl();
  const { replace, query, isReady } = useRouter();
  const [search, setSearch] = useState((query?.search as string) || '');

  const updateSearch = (searchTerm: string) => {
    const stringify = !searchTerm
      ? { ...omit(query, ['search']) }
      : { ...query, search: searchTerm };

    replace(
      {
        ...(path ? { pathname: path } : {}),
        query: qs.stringify(stringify),
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  const debouncedUpdateSearch = useCallback(debounce(updateSearch, 100), [
    replace,
  ]);

  useEffect(() => {
    if (isReady && search !== query?.search) {
      setSearch(query.search as string);
    }
  }, [query?.search]);

  useEffect(() => {
    if (
      query?.search !== search &&
      !(query?.search === undefined && search === '')
    ) {
      debouncedUpdateSearch(search as string);
    }
  }, [search]);

  return (
    <Box pb={[8, 10]}>
      <Container pt={[3, 6]} pb={!search ? [8, 12] : [3, 8]} {...pick(props)}>
        <Flex alignItems="center" justifyContent="center">
          <Flex
            position="relative"
            alignItems="center"
            justifyContent="center"
            maxWidth="570px"
            width="100%"
            css={css({
              input: {
                bg: 'grey100',
                boxShadow: 'none',
                border: 'none',
              },
              svg: {
                color: 'grey900',
              },
            })}
          >
            <Input
              width="100%"
              placeholder={intl.formatMessage(messages.searchPlaceholder)}
              icon={<SearchIcon />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              variant="unstyled"
              ml={2}
              fontSize="14px"
              fontWeight="medium"
              lineHeight="140%"
              letterSpacing="0.04em"
              disabled={!search}
              onClick={() => setSearch('')}
            >
              <FormattedMessage {...messages.clear} />
            </Button>
          </Flex>
        </Flex>
      </Container>
      {!search && <CollectionSection py={[0, 0]} items={collections?.items} />}

      {!!search && (
        <Container pt={0} pb={0}>
          <Grid filtersMapping={filtersMapping} search={search} />
        </Container>
      )}
    </Box>
  );
};
